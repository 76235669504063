<template>
<!-- -->
    <Dialog v-model:visible="display" :header='header' :modal="true" class='subscription-added-modal' @hide="logOut">
        {{bodyText}}

        <p>You will now be logged out.  You will be able to access your new features when you log back in.</p>
        <template #footer>
			<Button label="OK" class='confirm-button green-button__primary' @click="logOut"/>
		</template>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';

import AuthService from '../../service/AuthService';

export default {
    name: 'SubscriptionAddedModal',
    data() {
        return {
            display: false,
            header: 'Subscription Added!',
            bodyText: null
        }
    },


    components: {
        Dialog
    },

    methods: {
        open(message, headerText) {
            this.bodyText = message;
            if( headerText ) {
                this.header = headerText;
            }
            else {
                this.header = 'Subscription Added!';
            }
            this.display = true;
        },

        logOut() {
            this.display = false;
            AuthService.logout();
        }
    }
    
}
</script>

<style>

.subscription-added-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
	box-shadow: none;
}

.subscription-added-modal .p-dialog-header {
    padding: 30px 20px 20px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
    justify-content: center;
}

.subscription-added-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
    margin-right: auto;
    margin-left: auto;
}

.subscription-added-modal .p-dialog-content {
    color: black;
	padding: 0px 25px 30px;
    text-align: center;
    font-size: 16px;
}

.confirm-button {
    font-weight: bold;
    border-radius: 50px;
}

p {
    margin-top: 8px;
}
@media (max-width: 760px) {
    .subscription-added-modal {
        width: 100%;
        /*height: 100%;*/
    }
}
</style>