<template>
  <div class="layout-content">
    <div class="tab-div">
      <LoadingSpinner :showLoading="loading" />
      <div class="billing-container" v-if="!loading">
        <h1 class="header">Subscriptions & Passes</h1>
        <p class="details-link" @click="openFullComparison">View full comparison</p>
        <div class="switch-container">
            <div class='plan-container'>
              <h4 class="switch-title">Monthly</h4>
              <RadioButton v-model="selectedFrequency" value="Monthly" key="Monthly" />
            </div>
            <div class='plan-container'>
              <h4 class="switch-title">Annual</h4>
              <RadioButton v-model="selectedFrequency" value="Yearly" key="Annual" />
            </div>
        </div>
        <div class="save-container">
          <p>Get 2 months free with an annual subscription.</p>
        </div>
        <div class="subscriptions-container">
          <div class="subscription-card">
            <div class='subscription-title-container' v-if='!userIsPremiumSubscriber'>
              <h3 class="subscription-title">Free</h3>
              <span class='subscription-pill current'>Current</span>
            </div>
            <h3 class="subscription-title" v-else>Free</h3>
            <div class='subscription-description-container'>
              <p>A comprehensive set of tools and data to accelerate your research process.</p>
            </div>
            <div class="price-container">
              <h1 class="price"><span>$</span>0</h1>
              <p class="currency">USD</p>
            </div>
            <div class='bottom-content-container'>
              <ul class="feature-list">
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Full access to games and competitions</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Social/community features</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Market screener*</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Unlimited watchlists*</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Analysis tools*</span>
                </li>
                 <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Paper Portfolios**</span>
                </li>
                <li class="feature">
                   <span><small>*Limited to 5 reasearch sessions in a 30 day period.</small></span>
                </li>
                <li class="feature">
                  <span><small>**2 Trading accounts limited to 3 trades per day.</small></span>
                </li>
              </ul>
             
              
              <button class="cta-button manage" >Always Free</button>
            </div>
          </div>
          <div class="subscription-card recommended">
            <div class='subscription-title-container'>
              <h3 class="subscription-title">Premium</h3>
              <div class='subscription-pill-row'>
                <span class='subscription-pill recommended'>recommended</span>
                
              </div>
            </div>
            <div class='subscription-description-container'>
              <p class="subscription-description">Great for investors looking to take their research to the next level.</p>
            </div>
            <div class="price-container">
              <h1 class="price"><span>$</span>{{premiumPrice}}</h1>
              <p class="currency">USD</p>
            </div>
            <p class="frequency">{{frequency}}</p>
            <div class='bottom-content-container'>
              <ul class="feature-list" style="margin-bottom: 0px;margin-top:0px;">
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Everything in Free</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">North American company filings</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Shareholder information</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Customizable alerts</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Earnings calendar</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Dividends calendar</span>
                </li>
                 <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Cash flow forecaster</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Unlimited Paper Portfolios</span>
                </li>
                 <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Unlimited Research and Trading</span>
                </li>
                <li class="feature">
                  <i class="pi pi-check" />
                  <span class="feature-title">Level 2 Competition Trading Data</span>
                </li>
                  
              </ul>
              <Button  v-if="userIsInPremiumAnalysisTrial && !hasPremiumAnalysisSubscription" class="cta-button upgrade" @click="createCheckoutSession(selectedFrequency === 'Yearly' ? 4 : 3)" label="Upgrade From Trial"/>
              <template v-else>
                <template v-if="!userIsPremiumSubscriber || !hasPremiumAnalysisSubscription">
                  <Button :loading="upgrading" class="cta-button upgrade" @click="createCheckoutSession(selectedFrequency === 'Yearly' ? 4 : 3)" :label="upgradeButtonLabel(PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS'])"/>
                  
                </template>
                <Button  v-else class="cta-button manage" @click="createPortalSession" label="Manage Plan"/>
              </template>
              
            </div>
          </div>
          <div class="subscription-card">
            <div class='subscription-title-container'>
              <h3 class="subscription-title">Investor Pass</h3>
              
            </div>
            <div class='subscription-description-container'>
             
              <p class="subscription-description"><span class='investor-pass-pill recommended'>Premium Investor Pass</span></p>
              <p class="subscription-description"> No usage limits and access to all premium features, including Level 2 Competition Trading Data, for the duration of the pass.</p>

               <p class="subscription-description"><span class='investor-pass-pill basic'>Basic Investor Pass</span></p>
              <p class="subscription-description">Removes Free Plan usage limits on research and trading for the duration of the pass.</p>
             
            </div>
            <!-- <div class="price-container">
              <p><strong>$1000.00 USD Playdough for 1 Day Pass</strong></p>
            </div>
            <div class="price-container">
              <p><strong>$5000.00 USD Playdough for 30 Day Pass</strong></p>
            </div> -->
            <div class='subscription-description-containers'>
             <p class="subscription-description">All Passes come in 1, 14 and 30 day durations and can be purchased using <strong>Playdough</strong>.</p>
             <p class="subscription-description"> You can buy <strong>Playdough</strong> separately or you can earn it by:</p>
            </div>
            <div >
                <ul class="feature-list" style="padding-inline-start:unset;">
                  <li class="feature"><i class="pi pi-check" /> <span class="feature-title">Playing games like FinTrivia.</span></li>
                  <li class="feature"> <i class="pi pi-check" /><span class="feature-title">Submittting and voting on motions.</span></li>
                  <li class="feature"> <i class="pi pi-check" /><span class="feature-title">Asking/answering questions that help others.</span></li>
                  <li class="feature"> <i class="pi pi-check" /><span class="feature-title">Making good trades!</span></li>
                </ul>
            
             </div>
              
            <div class='bottom-content-container'>
              
                <Button class="cta-button upgrade" @click="buyPass" :loading="buyingPass" label="Buy Pass" :disabled="hasPremiumAnalysisSubscription"/>
           
            </div>
          </div>
        </div>
      </div>
      <div class="billing-container" v-if="activeUsagePasses && activeUsagePasses.length > 0 ">
        <div class="subscription-card" style="height:auto;">
         
          <h3 class="header">Your Active Investor Passes</h3>
        
        
        <ul class="feature-list" style="padding-inline-start:unset;margin-bottom:0;">
        
        <li class="feature pass-row"><span class="pass-desc-header">Pass Type</span><span class="pass-expiry-header">Expiry Date</span></li>
        </ul>

          <ul class="feature-list" style="padding-inline-start:unset;">
         <template v-for="(pass) of activeUsagePasses"  :key="pass.passId">
            <li class="feature pass-row"><span class="pass-desc">{{getPassDescription(pass)}}</span><span class="pass-expiry">{{getPassExpiry(pass)}}</span></li>
          </template>
          </ul>
        </div>
      </div>

    </div>
  </div>

  <SubscriptionAddedModal ref='subscriptionAddedModal' :bodyText="subscriptionAddedMessage" />
  <BuyInvestorPassModal ref='buyInvestorPassModal' @pass-selected="onPassSelected"/>
  <BuyPlayDoughModal @wad-selected="onWadSelected" ref="buyPlayDoughModal"/>
</template>

<script>
import {HOME_URL} from '../common/config';
import {PREMIUM_SUBSCRIPTION_TYPES, PREMIUM_PASS_IDS} from '../common/constants';
import PaymentService from '../service/PaymentService';
import PortfolioService from '../service/PortfolioService';

// import AuthService from '../service/AuthService';
// import UserService from '../service/UserService';
import UserUtils from '../utilities/UserUtils';
import TimeUtils from '../utilities/TimeUtils';

import RadioButton from 'primevue/radiobutton';
import LoadingSpinner from '../components/common/LoadingSpinner.vue';
import SubscriptionAddedModal from '../components/modal/SubscriptionAddedModal.vue';
import BuyInvestorPassModal from '../components/modal/BuyInvestorPassModal.vue';

import EventBus from '../event-bus';

import BrowserUtils from '../utilities/BrowserUtils';

import BuyPlayDoughModal from '../components/portfolio/modal/BuyPlayDoughModal.vue';

import Button from 'primevue/button';
export default {
  name: 'Billing',

  components: {
    RadioButton,
    LoadingSpinner,
    SubscriptionAddedModal,
    BuyInvestorPassModal,
    BuyPlayDoughModal,
    Button
  },
  
  data() {
    return {
      // CONSTANTS
      PREMIUM_SUBSCRIPTION_TYPES,

      loading: true,
      annual: false,
      selectedFrequency: 'Monthly',
      allCurrentSubscriptions: [],

      subscriptionAddedMessage: 'Your Premium subscription has been added to your account.',
      trialStartedMessage: 'You have successfully started your 2-week free trial.',
      passIssuedMessage: 'Your Investor pass has been issued.',
      cashAccounts: [],
      usdCashAccount: null,
      selectedPass: null,
      buyingPass: false,
      upgrading: false,
    }
  },

  mounted() {
      PaymentService.getAllSubscriptions(this.$store.getters['users/getUser'].userId).then((resp) => {
        if (resp.data.status === 'success') {
          this.allCurrentSubscriptions = resp.data.subscriptions;
        }
      })
      .catch(error => {
        console.log("Error getting all current subscriptions: " + error);
      })

      
  },

  computed: {
    
    

    activeUsagePasses() {
      return this.$store.state.users.user.activeUsagePasses;
    },


    userIsPremiumSubscriber() {
      return UserUtils.isPremiumSubscriber();
    },

    
    userIsBetaPremiumTester() {
      return UserUtils.isBetaPremiumTester();
    },

    userIsInPremiumPortfolioTrial() {
      if (this.premiumPortfolioTrialSubscription) {
        return TimeUtils.isSameOrAfterNow(this.premiumPortfolioTrialSubscription.stopDate, "MMM DD, YYYY h:mm:ss A");
      } else {
        return false;
      }
    },

    userIsInPremiumAnalysisTrial() {
      if (this.premiumAnalysisTrialSubscription) {
        return TimeUtils.isSameOrAfterNow(this.premiumAnalysisTrialSubscription.stopDate, "MMM DD, YYYY h:mm:ss A");
      } else {
        return false;
      }
    },

    hasPremiumAnalysisSubscription() {
      return this.$store.getters['users/subscriptionList'].some(sub => sub.subscriptionId === 3 || sub.subscriptionId === 4);
    },

    hasPremiumPortfolioSubscription() {
      return this.$store.getters['users/subscriptionList'].some(sub => sub.subscriptionId === 6 || sub.subscriptionId === 7);
    },

    premiumAnalysisTrialSubscription() {
      const sub = this.allCurrentSubscriptions.filter(sub => sub.subscriptionId === 5)[0];

      return sub === undefined ? null : sub;
    },

    premiumPortfolioTrialSubscription() {
      const sub = this.allCurrentSubscriptions.filter(sub => sub.subscriptionId === 8)[0];

      return sub === undefined ? null : sub;
     },

    premiumPrice() {
      if (this.selectedFrequency === 'Yearly') {
        return "69.50";
      } else {
        return "6.95";
      }
    },

    

    frequency() {
      if (this.selectedFrequency === 'Yearly') {
        return 'per year (2 months free with annual billing)';
      } else {
        return 'per month';
      }
    },

    premiumPortfolioFrequency() {
      if (this.selectedFrequency === 'Yearly') {
        return 'per year';
      } else {
        return 'per month';
      }
    }
  },

  activated() {
    this.loading = false;

    const paymentStatus = this.$route.query.payment;
    if (paymentStatus === 'complete') {
      //this.loading = true;
      //this.getLatestUser();
      if( this.$route.query.product ){
        this.$refs.subscriptionAddedModal.open('Your Investor Pass has been issued.', 'Investor Pass Issued!');
        //this.$toast.add({ severity: 'success', summary: 'Your Investor Pass has been issued and will be activated as soon as payment has been confirmed.', detail: '', life: 3000, group: 'center'});
      }
      else {
        this.$refs.subscriptionAddedModal.open(this.subscriptionAddedMessage);
      }
    } 
    this.getCashAccounts();
            
  },

  methods: {

    getPassDescription(pass) {
     
      if(pass) {
        
        if( PREMIUM_PASS_IDS.some( (id) => id == pass.passId ) ) {
          if( pass.passId == 6 ) {
            return "Premium 1 Day";
          }
          else if(pass.passId == 5 ) {
            return "Premium 14 Day";
          }
          else if( pass.passId == 4) {
            return "Premium 30 Day";
          }
          else if( pass.passId == 11){
            return "Premium Educational";
          }
          else {
            return ""
          }
          
        }
        else if( pass.passId == 3) {
          // competition
          return "Competition";
        }else {
          if( pass.passId == 2 ) {
            return "Basic 1 Day";
          }
          else if(pass.passId == 7 ) {
            return "Basic 14 Day";
          }
          else if( pass.passId == 1){
            return "Basic 30 Day";
          }
          else if( pass.passId == 10){
            return "Basic Educational";
          }
          else {
            return "";
          }
        }
      }
      else {
        return "";
      }
    },

    getPassExpiry(pass) {
     
      if(pass) {
        return TimeUtils.getFormattedTime(pass.endDate, 'YYYY-MM-DD HH:mm')
        
      }
      else {
        return "";
      }
    },

   
    buyPlaydough(whyText, hide1000, hide5000) {
            if(BrowserUtils.isNativeApp()) {
                this.$refs.buyPlaydoughViaWebsiteModal.open();
            } else {
                this.$refs.buyPlayDoughModal.open(this.usdCashAccount, whyText, hide1000, hide5000);
            }
        },

        onWadSelected( wadSelection ) {
          this.buyingPass = true;
           wadSelection['usagePassTypeId'] = this.selectedPass.passTypeId;
            PaymentService.createPlayDoughCheckoutSession(wadSelection).then(resp => {
                if (resp.data.status === 'success') {
                    window.location.href = resp.data.url;
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 5000,  group: 'center'  });
                  this.buyingPass = false;
                }
            }).catch(err => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 5000,  group: 'center'  });
                this.buyingPass = false;
            });
            
        },

     getCashAccounts(){
       PortfolioService.getCashAccounts().then(resp => {
            if (resp.data.status == 'success') {
              this.cashAccounts =  resp.data.cashAccounts;
              if( this.cashAccounts[0].currencyId == 2 ) {
                this.usdCashAccount = this.cashAccounts[0];
              }
              else {
                this.usdCashAccount = this.cashAccounts[1];
              }
            }
       });
      
    },

    buyPass() {
     
      this.$refs.buyInvestorPassModal.open();
    },

    onPassSelected( pass) {
      this.selectedPass = pass;
      console.log("pass  selected " +pass.passTypeId )
      if( this.usdCashAccount.totalValue < pass.cost ) {
        let hide1000 = (pass.cost - this.usdCashAccount.totalValue   > 1000 ? true : false);
        let hide5000 = (pass.cost - this.usdCashAccount.totalValue   > 5000 ? true : false)
        this.buyPlaydough("You'll need to buy more Playdough to complete your purchase.", hide1000, hide5000);
      }
      else {
        PaymentService.issueUsagePass(pass.passTypeId).then( resp=> {
          if( resp.data.status == "success") {
              //this.$toast.add({ severity: 'success', summary: 'Your Investor Pass has been issued.', detail: '', life: 5000, group: 'center'});
              this.$refs.subscriptionAddedModal.open('Your Investor Pass has been issued.', 'Investor Pass Issued!');
          }
          else {
            if( resp.data.message == 'nsf') {
              this.$toast.add({ severity: 'error', summary: 'Insufficent Funds', detail: "You don't currently have enought USD Playdough to complete the purchase.", life: 5000, group: 'center'});
              EventBus.emit('refresh-cash-accounts-balance');
            }
            else {
              this.$toast.add({ severity: 'error', summary: 'There was an unexpected issue. Contact us if the issue persists.', detail: resp.message, life: 5000, group: 'center'});
            }
        
          }
        });
      }
    },

    upgradeButtonLabel(type) {
      if (type === 'PremiumAnalysis') {
        if (this.premiumAnalysisTrialSubscription) {
          if (!this.userIsInPremiumAnalysisTrial) {
            return 'Ready to Subscribe';
          }
        } else {
          return 'Upgrade'
        }
      } else if (type === 'PremiumPortfolio') {
        if (this.premiumPortfolioTrialSubscription) {
          if (!this.userIsInPremiumPortfolioTrial) {
            return 'Ready to Subscribe';
          }
        } else {
          return 'Upgrade';
        }
      } 
    },

    createCheckoutSession(id) {
      this.upgrading = true;
      PaymentService.createCheckoutSession(id).then(resp => {
        if (resp.data.status === 'success') {
          window.location.href = resp.data.url;
          
        } else {
          this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000 });
          this.upgrading =false;
        }
      }).catch(err => {
        console.log(err);
        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000 });
        this.upgrading =false;
      });
    },

    createPortalSession() {
       this.upgrading = true;
      PaymentService.createPortalSession().then(resp => {
        if (resp.data.status === 'success') {
          window.location.href = resp.data.url;
        } else {
          this.$toast.add({ severity: 'error', summary: 'Error launching customer portal. Contact us if the issue persists.', detail: resp.message, life: 3000 });
          this.upgrading =false;
        }
      }).catch(err => {
        console.log(err);
        this.$toast.add({ severity: 'error', summary: 'Error launching customer portal. Contact us if the issue persists.', detail: err.message, life: 3000 });
        this.upgrading =false;
      });
    },

    openFullComparison() {
      window.open(HOME_URL + '/plans?loggedIn=true&fromBilling=true', '_blank');
    },

    startTrial(subTypeId) {
      this.loading = true;
      PaymentService.createFreeTrial(subTypeId)
        .then((data) => {
            if (data.data.status === 'success') {
                // this.$store.commit('users/SET_USER', data.data.user);

                // AuthService.changeUserObject(data.data.user);

                this.loading = false;
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'You have successfully started your 2-week free trial.',
                //     life: 3000,
                //     group: 'center'
                // });

                this.$refs.subscriptionAddedModal.open(this.trialStartedMessage);
            } else {
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error starting free trial. Please try again later or contact support.',
                    life: 3000,
                    group: 'center'
                });
            }
        })
        .catch(() => {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Error starting free trial. Please try again later or contact support.',
                life: 3000,
                group: 'center'
            });
        });
      },

    // getLatestUser() {
    //   UserService.getLatestUserRecord().then(res => {
    //     if (res.status === 200 && res.data.status === 'success') {
    //       const user = res.data.user;
    //       const premiumSub = (id) => id === 4 || id === 3;
    //       const premiumPortfolioSub = (id) => id === 6 || id === 7;
    //       const isSub = user.subscriptions?.filter(sub => premiumSub(sub.subscriptionId) || premiumPortfolioSub(sub.subscriptionId)).length > 0;
    //       if (isSub) {
    //         this.$store.commit('users/SET_USER', user);

    //         AuthService.changeUserObject(user);
    
    //         this.loading = false;
    //         this.$toast.add({ severity: 'success', summary: `Your Premium subscription has been added to your account.`, life: 4000, group: 'center' });
    //       } 
    //     } else {
    //       this.loading = false;
    //       this.$toast.add({ severity: 'error', summary: 'Error getting latest user record. Please try again later or contact support.', life: 3000, group: 'center' });
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //     this.loading = false;
    //     this.$toast.add({ severity: 'error', summary: 'Error getting latest user record. Please try again later or contact support.', life: 3000, group: 'center' });
    //   });
    // }
  },
}

</script>

<style scoped>
.pass-row{
  padding: 5px;
}

.pass-desc-header{
  font-weight: bold;
  margin-right: auto;
  font-size: 16px;
}

.pass-expiry-header{
  float:right;
  margin-left: 10px;
  font-weight: bold;
}

.pass-desc{
  
  margin-right: auto;
  font-size: 14px;
}

.pass-expiry{
  float:right;
  margin-left: 10px;
  font-size: 14px;
  
}


.billing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 0px;
  font-family: 'Trebuchet MS';
}

.header {
  font-size: 24px;
  color: #32364e;
  text-align: center;
  margin-bottom: 14px;
}

.details-link {
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 26px;
}

.details-link:hover {
  color: #3c9;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.switch-title {
  margin: 0 16px 0 0;
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
}

.plan-container {
  display: flex;
  margin-right: 16px;
}
.plan-container:last-child {
  margin-right: 0px;
}

.save-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
  padding: 10px 30px;
  border: 1px solid #3c9;
  border-radius: 8px;
  background-color: rgba(51, 204, 153, 0.427);
  color: #32364e;
  font-size: 14px;
}

.subscriptions-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.subscription-card {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-right: 24px;
  width: 350px;
  height: 560px;
}

.subscription-card.recommended {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #3c9;
}

.subscription-card:last-child {
  margin-right: 0px;
}

.subscription-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}
.subscription-title-container .subscription-title {
  margin-bottom: 4px;
}

.subscription-title {
  color: #32364e;
  text-align: center;
}

/* .subscription-title span {
  padding: 4px 8px;
  background-color: #693BF5;
  color: #fff;
  border-radius: 500px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  transform: translate(8%, 8%);
} */

.subscription-pill-row {
  display: flex;
}

.subscription-pill {
  padding: 4px 8px;
  margin-right: 4px;
  color: #fff;
  border-radius: 500px;
  font-size: 10px;
  text-transform: uppercase;
}

.investor-pass-pill {
  padding: 4px 8px;
  margin-right: 4px;
  color: #fff;
  border-radius: 500px;
  font-size: 12px;
  text-transform: uppercase;
}

.investor-pass-pill.basic {
  background-color: #3c9;

}
.investor-pass-pill.recommended {
  background-color: #693BF5;

}
.subscription-pill:last-child {
  margin-right: 0;
}
.subscription-pill.recommended {
  background-color: #693BF5;
}
.subscription-pill.current {
  background-color: #32364e;
}
.subscription-pill.trial-expired {
  background-color: #e63e3e;
}

.subscription-description-container {
  color: black;
  text-align: center;
  margin-bottom: 16px;
}
.subscription-description-container p {
  margin-bottom: 0;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price {
  margin: 0;
  margin-right: 6px;
  color: #32364e;
  line-height: 1;
}

.price span {
  font-size: 16px;
  position: absolute;
  transform: translate(-100%, 50%);
}

.frequency {
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.feature-list {
  list-style: none;
  margin-bottom: 24px;
}
.feature-list:last-child {
  margin-bottom: 0px;
}

.pi-check {
  color: #3c9;
  margin-right: 8px;
  margin-bottom: 12px;
}

.cta-button {
  width: 100%;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #3c9;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
}

.cta-button:hover {
  opacity: 0.9;
}

.manage {
  background-color: #32364e;
}

.downgrade {
  background-color: #e63e3e;
}

.upgrade {
  background-color: #3c9;
}

.trial-text {
  color: #999;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.trial-text:hover {
  color: #3c9;
}

.bottom-content-container {
  margin-top: auto;
}

@media (max-width: 1224px) {
 .subscription-card {
   width: 280px;
   height: 610px;
   padding: 10px 14px;
 }

 .feature-list {
  padding-left: 20px;
 }
}

@media (max-width: 760px) {
  
  .subscriptions-container {
    display: block;
    margin-bottom:30px;
    
  }

  .subscription-card {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0px;
  }
}
</style>